<template>
  <div v-show="status.applyState">
    <ApplyState ref="applyState" @back="back"></ApplyState>
  </div>

  <div class="score-query" v-show="!status.applyState">
    <el-row class="score-query-title">
      <el-col>
        <div style="position: relative">
          <el-icon class="btn" @click="$router.push('/protal')"
            ><ArrowLeftBold
          /></el-icon>
        </div>
        我的报考</el-col
      >
    </el-row>
    <el-row v-if="state.errorMessage">
      <el-col
        ><div class="errorMessage">{{ state.errorMessage }}</div></el-col
      >
    </el-row>
    <el-row v-else class="message-content">
      <el-col>
        <div style="height: 75vh; overflow: hidden">
          <div
            class="message-item"
            v-for="(item, index) in rowsList"
            :key="index"
          >
            <div class="message-item-left">
              <div>考试名称：{{ item.examName }}</div>
              <div>
                报名时间：<span
                  >{{
                    item.examStartTime
                  }}
                  ~
                  {{
                    item.examEndTime
                  }}</span
                >
              </div>
            </div>

            <div class="message-item-right">
              <el-button @click="getApplyStatePages(item)">进入</el-button>
            </div>
          </div>
        </div>

        <el-pagination
          :page-size="pagination.pageSize"
          layout="prev, pager, next"
          :total="pagination.total"
          style="float: right"
          @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>
  </div>
</template>
<script setup>
import { post, get } from "@/api/api";
import { onMounted, reactive, ref, watch, getCurrentInstance, nextTick } from "@vue/runtime-core";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { ArrowLeftBold, CircleCheck } from "@element-plus/icons-vue";
import moment from "moment";
import { useRouter, useRoute } from 'vue-router'
import ApplyState from "../pages/candidateEntrance/index.vue";
const router = useRouter()
const route = useRoute()
const currentInstance = getCurrentInstance()
onMounted(() => {
  getPersonalExamList();
});
// data
// list数据
let rowsList = ref([]);
// 页面状态
let status = reactive({
  applyState: false,
});
let state = reactive({
  errorMessage: '',
});
// 分页数据
let pagination = reactive({
  pageSize: 6,
  pageNo: 1,
  total: 0,
});

// 分页切换
const handleCurrentChange = (num) => {
  pagination.pageNo = num;
  getPersonalExamList();
};
// 获取已报考考试列表
const getPersonalExamList = async () => {
  let res = await get("/examApply/getExamList", pagination);
  if (res.code === 200) {
    rowsList.value = res.result.list;
    pagination.total = res.result.total;
    if (res.result.list.length == 0) {
      state.errorMessage = res.message
    } else {
      state.errorMessage = ''
    }
  } else {
    ElMessage.error(res.message)
  }
};
// 查看报考进度
const getApplyStatePages = (row) => {
  status.applyState = true
  nextTick(() => {
    currentInstance.proxy.$refs.applyState.enroll(row)
    currentInstance.proxy.$refs.applyState.status.examStep = 7
  })
};
const back = () => {
  status.applyState = false
  console.log('back');
}


</script>
<style lang="scss" scoped>
.score-query {
  height: 89vh;
  background-color: #ffffff;
}
.message-content {
  padding: 0 0.3125rem;
}
.score-query-title {
  text-align: center;
  height: 0.3125rem;
  line-height: 0.3125rem;
  font-size: 0.1563rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  border-bottom: 0.0052rem solid #e5e5e5;
  margin-bottom: 0.1042rem;
  .btn {
    position: absolute;
    left: 0;
    width: 0.3125rem;
    height: 0.3125rem;
    font-size: 0.0833rem;
    cursor: pointer;
  }
}
.message-item {
  display: flex;
  justify-content: space-between;
  // height: 0.4896rem;
  height: 10vh;
  background: #ffffff;
  border: 0.0052rem solid #d2d2d2;
  border-radius: 0.0208rem;
  margin-bottom: 0.1042rem;
  // cursor: pointer;
  padding-left: 0.1042rem;
  border-left: 0.0208rem solid #097afa;
  .message-item-left {
    width: 90%;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    div {
      font-size: 0.0938rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      padding: 0.0313rem 0;
    }

    div:nth-child(2) span {
      font-size: 0.0729rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
    }
  }
  .message-item-right {
    width: 10%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 0.1042rem;
  }
}
// .message-item:hover {
//   background-color: #eee;
// }
.el-button {
  color: #097afa;
  border-color: #097afa;
}
.errorMessage {
  height: 3.6979rem;
  line-height: 3.6979rem;
  font-size: 24px;
  color: #ccc;
  text-align: center;
}
</style>